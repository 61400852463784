
import { Component, Vue } from 'vue-property-decorator'
import { Machine } from '@/types/material.d'
import { Page } from '@/types/common.d'

@Component
export default class extends Vue {
    page = 1
    size = 10
    total = 0
    loading = false
    tableData: Machine[] = []
    searchForm = {
      machineryName: '' // 机械名称
    }

    created () {
      this.loadData()
    }

    // 获取机械表格
    loadData () {
      this.loading = true
      this.$axios.get<Page<Machine>>(this.$apis.material.selectMachineryByPage, {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      }).finally(() => {
        this.loading = false
      })
    }

    // 查询
    onSearch () {
      this.page = 1
      this.loadData()
    }

    // 删除机械
    onDelete (machineryId: string) {
      this.$confirm('确认删除该机械吗？', '提示').then(() => {
        this.$axios.post(this.$apis.material.deleteMachinery, {
          machineryId
        }).then(() => {
          this.$message.success('删除成功!')
          this.loadData()
        })
      })
    }
}
